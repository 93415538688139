import requset from "@/utils/request";

/**
 * @description: 获取新闻列表接口
 * @param type 上太新闻,行业动态,影像风采
 */
export function getNesListApi(data) {
  return requset({
    url: `/news`,
    method: "get",
    params:data
  });
}



/**
 * @description: 产品浏览量ID 这个ID是唯一的,线上环境和线下环境都是一样的
 * @智能虫情测报仪ST-CQ101        bcbc814ab96d41a19c27a77764a04adc
 * @智能高空虫情测报仪ST-GK101    1c317ff1e1c84c9997e817df289feb8a
 * @光伏自动气象测报站ST-X101     9a311453d41b4b35a97b29a4e1a0a522
 * @智能水肥机                    6a6bdb966da543d9bd14130f1989d527
 * @太阳能墒情仪ST-XJ101          39e0c778fb35490a9a828b37b14be796
 * @智能孢子捕捉仪ST-BZ101        04a259da6e8b425e9c33f9541c8be59c
 * @太阳能捕虫器ST-FX101          34536feaab8540e0ad83933413fb794f
 * @太阳能捕虫器ST-FX301          56f1227c7d0a462cb8c70588b7144839
 * @太阳能路灯捕虫器ST-LD/FX      dc815645c7b24668bdc8532a66cf9a17
 * @智能巡检无人车ST-XJ101        d0f2301918ae4623bb7ffe098b5e910d
 * @全地形大负载多功能无人车ST-TK101  bd49c710679e462793e5867324f1f802
 * @智能巡检无人机                055d6c3e66ed428caeaf533b2c22ecf4
 * @多功能无人机                  ad54458f8b924454b892e6a270fa2c83
 * @生物有机肥                    182ae191b8484fe89b3c5787787e2fa3
 */
/**
 * @description: 方案浏览量ID
 * @智慧温室解决方案               043ad33f5c0942f8a5a15ec10135dd24
 * @智慧生产管理解决方案            17661d2235cd4f9f92d97356f6df5911
 * @水肥一体化解决方案              6233d80dad4b4de1a53506d37aa67d3e
 * @病虫害绿色防控解决方案           c635651a07b44b9a80171551ef016a47
 */

/**
 * @description: 浏览量
 * @param type 1:产品 2:方案 3:新闻
 * @param id 产品id,方案id,新闻id
 */
export function getNesViewApi(data) {
  return requset({
    url: `/Browse`,
    method: "post",
    // params:data,
    data
  });
}

/**
 * @description: 获取招聘信息接口
 * @param page
 */
export function getRecruitListApi(data) {
  return requset({
    url: `/recruit`,
    method: "get",
    params:data,
  });
}

/**
 * @description: 获取招聘信息职位类型
 */
export function getRecruitTypeApi() {
  return requset({
    url: `/recruit/tree`,
    method: "get"
  });

}

/**
 * @description: 获取合作信息城市 area
 */
export function getAreaApi() {
  return requset({
    url: `/area/tree`,
    method: "get"
  });

}

/**
 * @description: 新增合作
 */
export function addCooperationApi(data) {
  return requset({
    url: `/cooperation`,
    method: "post",
    data
  });
}

/**
 * @description: 获取轮播图图片
 * @param code
 * @首页轮播图               H8pro1Ban08
 * @产品中心                 p5pro1Ban05
 * @病虫害绿色防控解决方案   BCPGFC001Ban03
 * @水肥一体化解决方案       SFYTJF001Ban003
 * @智慧生产管理解决方案      ZHSCGL001Ban008
 * @智能温室解决方案         ZHWJS001Ban004
 * @员工福利                 YGFL001Ban05
 */

export function getBannerApi(code) {
  return requset({
    url: `/banner`,
    method: "get",
    params:code
  });

}

/**
 * @description: 获取轮播图图位置
 */
export function getBannerPictureApi(id) {
  return requset({
    url: `/picture`,
    method: "get",
    params:id
  });

}
