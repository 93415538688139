import axios from "axios";
import { Message } from 'element-ui';
const requset = axios.create({
  baseURL: 'https://back.stkjiot.com/website',
  // baseURL: 'https://back.stkjiot.com/website',
  timeout: 50000
});
// 请求拦截器
requset.interceptors.request.use(
  response => {
    return response
  }
)
// 响应拦截器
requset.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Message({
      message: '网络异常，请稍后重试!',
      type: 'error',
      duration: 1000
    })

    return Promise.reject(error);
  }
)
export default requset;
